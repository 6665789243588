<template>
  <div>
    <b-modal
      id="modal-hold"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Hold Stock"
    >
      <b-form>
        <b-form-group
          label="Hold till"
          invalid-feedback="Date is required"
          ref="holdDate"
        >
          <flat-pickr
            :config="config"
            v-model="holdObj.holdTill"
            class="form-control"
            placeholder="Select date"
            @on-change="CheckDate()"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="markHold()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="holding"
          >
            <b-spinner v-if="holding" small type="grow" />
            <span v-else class="align-middle">Hold</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-inv"
      bg-variant="white"
      v-model="visibility1"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Inventory</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility1 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label=""
              invalid-feedback="Image are required"
              required
              ref="image"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  label="Loading..."
                  variant="primary"
                  class="m-5"
                />
                <b-img
                  v-else-if="!mediaLoaded"
                  ref="previewEl"
                  src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                  @click="$refs.invImage.click()"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <div v-else class="d-flex align-items-center">
                  <b-img
                    ref="previewEl"
                    :src="invObj.image"
                    @click="$refs.invImage.click()"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="text-danger cursor-pointer ml-1"
                    @click="removeImage()"
                  />
                </div>
                <input
                  type="file"
                  id="invImage"
                  hidden
                  ref="invImage"
                  @change="addImage()"
                  accept="image/*,"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group invalid-feedback="Category is required." ref="catID">
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Category</label
                >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  v-b-tooltip.hover.top
                  title="Manage Category"
                  @click="openCat(0)"
                />
              </div>
              <v-select
                ref="catID"
                v-model="invObj.categoryID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                placeholder="select"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group invalid-feedback="Vendor is required." ref="suppID">
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Vendor</label
                >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  v-b-tooltip.hover.top
                  title="Manage Vendor"
                  @click="openSupp()"
                />
              </div>
              <v-select
                ref="suppID"
                v-model="invObj.supplierID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="suppliers"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                placeholder="select"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              invalid-feedback="Warehouse is required."
              ref="wareID"
            >
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Warehouse</label
                >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  v-b-tooltip.hover.top
                  title="Manage Warehouse"
                  @click="openWare()"
                />
              </div>
              <v-select
                ref="wareID"
                v-model="invObj.warehouseID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="warehouses"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                placeholder="select"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Product Name"
              invalid-feedback="Product name is required."
            >
              <b-form-input
                ref="pname"
                v-model="invObj.name"
                placeholder="Enter product name here"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="SKU Number"
              invalid-feedback="SKU Number is required."
            >
              <b-form-input
                ref="sku"
                v-model="invObj.sku"
                placeholder="Enter sku"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Purchase Price"
              invalid-feedback="Purchase Price is required."
            >
              <b-form-input
                ref="pprice"
                type="number"
                v-model="invObj.purchasePrice"
                placeholder="Enter price"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Selling Price"
              invalid-feedback="Selling Price is required."
            >
              <b-form-input
                ref="sprice"
                type="number"
                v-model="invObj.sellingPrice"
                placeholder="Enter price"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Quantity"
              invalid-feedback="Quantity is required."
            >
              <b-form-input
                ref="quantity"
                type="number"
                v-model="invObj.quantity"
                placeholder="Enter quantity"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Restock Quantity"
              invalid-feedback="Restock Quantity is required."
            >
              <b-form-input
                ref="restock"
                type="number"
                v-model="invObj.restockQuantity"
                placeholder="Enter restock quantity"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Department"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Categories</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Category name"
              invalid-feedback="Category name is required."
            >
              <b-form-input
                ref="name"
                placeholder="Enter name"
                v-model="myObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="addCat()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-3"
          show-empty
          :items="categories"
          :fields="catFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="openCat(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="delCat(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-warehouse"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Warehouse</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Name"
              invalid-feedback="Warehouse name is required."
            >
              <b-form-input
                ref="wname"
                v-model="wObj.name"
                placeholder="Enter warehouse name here"
                @focusout="checkWName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveWare()"
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="warehouses"
          :fields="wFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editWare(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteWare(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-supplier"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Vendors</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Vendor name is required."
            >
              <b-form-input
                ref="sname"
                v-model="sObj.name"
                placeholder="Enter vendor name here"
                @focusout="checkSuppName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Contact"
              invalid-feedback="Contact is required."
            >
              <vue-tel-input
                v-model="sObj.contact"
                :dropdownOptions="dropOptions"
              ></vue-tel-input>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveSupp()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-2"
          show-empty
          :items="suppliers"
          :fields="spFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editSupp(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteSupp(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="end" class="text-right">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in categories"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :text="d.name"
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            right
            @click="LoadData(d.id)"
          >
            <b-dropdown-item @click="openCat(d)"> Edit </b-dropdown-item>
            <b-dropdown-item @click="delCat(d.id)"> Delete </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
        </b-col>
        <b-col
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
        >
          <b-button
            @click="openCat(0)"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="open(0)"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Inventory</span>
          </b-button>
        </b-col>
        <!-- <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="openWare(0)"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Warehouse</span>
          </b-button>
        </b-col> 
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            :to="{
              name: 'sales-transactions',
            }"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
          >
            <span class="align-middle">View Sales</span>
          </b-button>
        </b-col>
        -->

        <b-col
          class="mt-lg-0 mt-md-1 mt-sm-1 mt-1 d-flex align-items-center"
          xl="10"
          lg="9"
          md="12"
          sm="12"
          cols="12"
        >
          <b-form-group class="mb-0 w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <!-- <b-button
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon ml-50"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small varaint="primary" />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button> -->
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(image)="data">
          <b-img
            v-if="data.value"
            :src="data.value"
            height="60px"
            width="60px"
            style="border-radius: 10px"
            alt="pic"
          />
          <b-img
            v-else
            src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
            height="60px"
            width="60px"
            style="border-radius: 10px"
            alt="pic"
          />
        </template>
        <template #cell(name)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(supplierID)="data">
          <b-badge variant="light-primary">
            {{ getSuppName(data.value) }}
          </b-badge>
        </template>
        <template #cell(quantity)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(sellingPrice)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="ml-2">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="open(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="data.item.isHold"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.left
              title="Unhold Stock"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="unhold(data.item)"
            >
              <feather-icon icon="CheckIcon" class="" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.left
              title="Hold Stock"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="openHold(data.item)"
            >
              <feather-icon icon="XIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="delProduct(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      request: false,
      dataLoading: false,
      exporting: false,
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      visibility: false,
      visibility1: false,
      visibility2: false,
      visibility3: false,
      fields: [
        { label: "image", key: "image" },
        { label: "name", key: "name" },
        { label: "vendor", key: "supplierID" },
        { label: "quantity", key: "quantity" },
        { label: "selling price", key: "sellingPrice" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      myObj: {},
      wObj: {},
      warehouses: [
        //{ id: 1, name: "School Supply" }
      ],
      wFields: [
        { label: "name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      catFields: [
        { label: "name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      buttonIcon: "",
      sObj: {},
      suppliers: [
        // { id: 1, name: "Ali" },
        // { id: 2, name: "Ahmed" },
        // { id: 3, name: "Hassan" },
      ],
      spFields: [
        { label: "name", key: "name" },
        { label: "contact", key: "contact" },
        { key: "actions", label: "actions" },
      ],
      categories: [
        // { id: 1, name: "Apparel" },
        // { id: 2, name: "Stationary" },
        // { id: 3, name: "Electronics" },
      ],
      current: 0,
      products: [
        {
          id: 1,
          name: "Bag",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 120,
          purchasePrice: 4500,
          sellingPrice: 110,
          image: require("@/assets/images/myimages/bag.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 3,
          name: "Crayons",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 10,
          purchasePrice: 100,
          sellingPrice: 15,
          image: require("@/assets/images/myimages/crayons.jpeg"),
          restockQuantity: 20,
          isHold: true,
        },
        {
          id: 4,
          name: "Color Paper",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 100,
          purchasePrice: 4000,
          sellingPrice: 12,
          image: require("@/assets/images/myimages/papers.jpg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 5,
          name: "Pens Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 50,
          purchasePrice: 1500,
          sellingPrice: 25,
          image: require("@/assets/images/myimages/pens.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 6,
          name: "Notebook Pack",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 80,
          purchasePrice: 3000,
          sellingPrice: 20,
          image: require("@/assets/images/myimages/notebookpack.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 7,
          name: "Sticky Notes Bundle",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 120,
          purchasePrice: 2000,
          sellingPrice: 15,
          image: require("@/assets/images/myimages/sticky.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 8,
          name: "Journal",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 60,
          purchasePrice: 2500,
          sellingPrice: 18,
          image: require("@/assets/images/myimages/journal.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 9,
          name: "Eraser Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 200,
          purchasePrice: 1000,
          sellingPrice: 8,
          image: require("@/assets/images/myimages/eraser.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 10,
          name: "School Sweaters",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 50,
          purchasePrice: 8000,
          sellingPrice: 200,
          image: require("@/assets/images/myimages/sweater.png"),
          restockQuantity: 20,
          isHold: true,
        },
        {
          id: 12,
          name: "Laptop Cooling Pad",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 50,
          purchasePrice: 11000,
          sellingPrice: 250,
          image: require("@/assets/images/myimages/cooling.jpg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 13,
          name: "Wireless Microphones",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 0,
          purchasePrice: 2500,
          sellingPrice: 180,
          image: require("@/assets/images/myimages/microphone.jpg"),
          restockQuantity: 20,
          isHold: false,
        },
        {
          id: 2,
          name: "Shoes",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 60,
          purchasePrice: 2000,
          sellingPrice: 150,
          image: require("@/assets/images/myimages/shoes.jpeg"),
          restockQuantity: 20,
          isHold: false,
        },
      ],
      invObj: {},
      docLoading: false,
      mediaLoaded: false,
      sidebarButton: "",
      sidebarTitle: "",
      holding: false,
      holdObj: {},
    };
  },
  computed: {
    filters() {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  created() {
    // this.LoadData(this.current);
    this.LoadCat(0);
    this.LoadWare();
    this.LoadSupplier();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getSuppName(id) {
      let obj = this.suppliers.find((el) => el.id == id);
      return obj ? obj.name : "";
    },

    async LoadData(id) {
      this.dataLoading = true;
      this.current = id;
      var obj = {
        url:
          this.$store.state.domain +
          "posStock/LoadByCat?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&catID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.dataLoading = false;
      // this.items = this.products.filter((el) => el.categoryID == id);
    },
    openCat(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          name: "",
          campusID: this.$store.state.userData.cId,
        };
        // this.sidebarTitle = "Add Category";
        this.sidebarButton = "Save";
      } else {
        this.myObj = { ...id };
        // this.sidebarTitle = "Update Category";
        this.sidebarButton = "Update";
      }
      this.visibility = true;
      var elem = this.$refs["name"];
      elem.state = undefined;
    },
    checkName() {
      var elem = this.$refs["name"];
      if (this.myObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async addCat() {
      if (this.checkName() == true) {
        this.request = true;
        if (this.myObj.id == 0) {
          // this.myObj.id =
          //   Math.max(...this.categories.map((obj) => obj.id), 0) + 1;
          // this.categories.push(this.myObj);
          var status = await this.post({
            url:
              this.$store.state.domain +
              "posCategories?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Category added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          // let obj = this.categories.findIndex((el) => el.id == this.myObj.id);
          // this.categories.splice(obj, 1, this.myObj);
          var status = await this.put({
            url:
              this.$store.state.domain +
              "posCategories/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Category updated successfully!",
            context: this,
            body: this.myObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadCat();
          // this.visibility = false;
          var elem = this.$refs["name"];
          elem.state = undefined;
          this.sidebarButton = "Save";
          this.myObj = {
            id: 0,
            name: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.request = false;
      }
    },
    async delCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "posCategories/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadCat(0);
        // this.categories = this.categories.filter((el) => el.id != id);
      }
    },

    openWare(id) {
      this.buttonIcon = "PlusIcon";
      this.visibility2 = true;

      this.wObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["wname"];
      elem.state = undefined;
    },
    editWare(item) {
      this.wObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["wname"];
      elem.state = undefined;
    },
    checkWName() {
      var elem = this.$refs["wname"];
      if (this.wObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveWare() {
      if (this.checkWName() == true) {
        this.request = true;
        if (this.wObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "posWarehouse?db=" +
              this.$store.state.userData.db,
            body: this.wObj,
            message: "Warehouse added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "posWarehouse/" +
              this.wObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Warehouse updated successfully!",
            context: this,
            body: this.wObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadWare();
          var elem = this.$refs["wname"];
          elem.state = undefined;
          this.wObj = {
            id: 0,
            name: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.request = false;
      }
    },
    async deleteWare(id) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "posWarehouse/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Warehouse removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadWare();
      }
    },

    openSupp() {
      this.visibility3 = true;

      this.sObj = {
        id: 0,
        name: "",
        contact: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["sname"];
      elem.state = undefined;
    },
    editSupp(item) {
      this.sObj = { ...item };
      var elem = this.$refs["sname"];
      elem.state = undefined;
    },
    checkSuppName() {
      var elem = this.$refs["sname"];
      if (this.sObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSupp() {
      if (this.checkSuppName() == true) {
        this.request = true;
        if (this.sObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "posSupplier?db=" +
              this.$store.state.userData.db,
            body: this.sObj,
            message: "Vendor added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "posSupplier/" +
              this.sObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Vendor updated successfully!",
            context: this,
            body: this.sObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadSupplier();
          var elem = this.$refs["sname"];
          elem.state = undefined;
          this.sObj = {
            id: 0,
            name: "",
            contact: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.request = false;
      }
    },
    async deleteSupp(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "posSupplier/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Vendor removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadSupplier();
      }
    },

    open(id) {
      if (id == 0) {
        this.invObj = {
          id: 0,
          name: "",
          categoryID: this.current,
          warehouseID: 0,
          supplierID: 0,
          quantity: 0,
          sellingPrice: 0,
          purchasePrice: 0,
          restockQuantity: 0,
          isHold: false,
          holdTill: new Date().toJSON(),
          image: "",
          sku: "",
          campusID: this.$store.state.userData.cId,
        };
        this.mediaLoaded = false;
      } else {
        this.invObj = { ...id };
        if (this.invObj.image) this.mediaLoaded = true;
        else this.mediaLoaded = false;
      }
      this.visibility1 = true;
      var elem = this.$refs["image"];
      elem.state = undefined;
      var elem = this.$refs["catID"];
      elem.state = undefined;
      var elem = this.$refs["suppID"];
      elem.state = undefined;
      var elem = this.$refs["wareID"];
      elem.state = undefined;
      var elem = this.$refs["pname"];
      elem.state = undefined;
      var elem = this.$refs["pprice"];
      elem.state = undefined;
      var elem = this.$refs["quantity"];
      elem.state = undefined;
      var elem = this.$refs["sprice"];
      elem.state = undefined;
      var elem = this.$refs["restock"];
      elem.state = undefined;
      var elem = this.$refs["sku"];
      elem.state = undefined;
    },
    addImage() {
      let media = this.$refs.invImage.files[0];
      if (media != "") {
        this.mediaLoaded = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", media);
        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.invObj.image = resp;
            this.docLoading = false;
            this.mediaLoaded = true;
            // console.log(this.invObj.image);
            this.CheckImage();
          });
      }
    },
    removeImage() {
      this.invObj.image = "";
      this.mediaLoaded = false;
      this.$refs.invImage.files = null;
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (!this.invObj.image) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatID() {
      var elem = this.$refs["catID"];
      if (this.invObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSuppID() {
      var elem = this.$refs["suppID"];
      if (this.invObj.supplierID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckWareID() {
      var elem = this.$refs["wareID"];
      if (this.invObj.warehouseID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPName() {
      var elem = this.$refs["pname"];
      if (this.invObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSKU() {
      var elem = this.$refs["sku"];
      if (this.invObj.sku.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPP() {
      var elem = this.$refs["pprice"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.invObj.purchasePrice);
      if (!re.test(this.invObj.purchasePrice) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQuantity() {
      var elem = this.$refs["quantity"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.invObj.quantity);
      if (!re.test(this.invObj.quantity) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSP() {
      var elem = this.$refs["sprice"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.invObj.sellingPrice);
      if (!re.test(this.invObj.sellingPrice) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRestock() {
      var elem = this.$refs["restock"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.invObj.restockQuantity);
      if (!re.test(this.invObj.restockQuantity) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      if (
        this.CheckCatID() == false ||
        this.CheckSuppID() == false ||
        this.CheckWareID() == false ||
        this.CheckPName() == false ||
        this.CheckImage() == false ||
        this.CheckSKU() == false ||
        this.CheckPP() == false ||
        this.CheckQuantity() == false ||
        this.CheckSP() == false ||
        this.CheckRestock() == false
      ) {
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.request = true;
        this.invObj.quantity = parseInt(this.invObj.quantity);
        this.invObj.sellingPrice = parseInt(this.invObj.sellingPrice);
        this.invObj.purchasePrice = parseInt(this.invObj.purchasePrice);
        this.invObj.restockQuantity = parseInt(this.invObj.restockQuantity);
        // console.log(this.invObj);

        if (this.invObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "posStock?db=" +
              this.$store.state.userData.db,
            body: this.invObj,
            message: "Stock added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "posStock/" +
              this.invObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Stock updated successfully!",
            context: this,
            body: this.invObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadData(this.current);
          this.visibility1 = false;
        }
        this.request = false;
      }
    },
    async delProduct(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "posStock/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Stock removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(this.current);
      }
    },
    openHold(item) {
      this.holdObj = { ...item };
      this.$bvModal.show("modal-hold");
    },
    CheckDate() {
      var elem = this.$refs["holdDate"];
      if (!this.holdObj.holdTill) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markHold() {
      if (this.CheckDate() == true) {
        this.holding = true;
        this.holdObj.isHold = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "posStock/" +
            this.holdObj.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Stock hold successfully!",
          context: this,
          body: this.holdObj,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.$bvModal.hide("modal-hold");
          this.LoadData(this.current);
        }
        this.holding = false;
      }
    },
    async unhold(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to unhold the stock!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Unhold it!",
      });

      if (result.isConfirmed) {
        item.isHold = false;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "posStock/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Stock unhold successfully!",
          context: this,
          body: item,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData(this.current);
      }
    },

    async LoadCat(val) {
      var obj = {
        url:
          this.$store.state.domain +
          "posCategories?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);
      if (
        (this.categories.length > 0 && val == 0) ||
        this.categories.length == 1
      ) {
        this.LoadData(this.categories[0].id);
      }
    },
    async LoadWare() {
      var obj = {
        url:
          this.$store.state.domain +
          "posWarehouse?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.warehouses = await this.get(obj);
    },
    async LoadSupplier() {
      var obj = {
        url:
          this.$store.state.domain +
          "posSupplier?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.suppliers = await this.get(obj);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
